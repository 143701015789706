import { ButtonMenu, ButtonMenuItem, CopyIcon, Flex, Text, useMatchBreakpoints, SearchIcon } from '@pancakeswap/uikit'
import { AutoComplete } from 'antd'
import Container from 'components/Layout/Container'
import Header from 'components/Layout/Header'
import PageFullWidth from 'components/Layout/PageFullWidth'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useTheme from 'hooks/useTheme'
import { useEffect, useMemo, useState } from 'react'
import { GetDataHome, UseHome } from 'state/home'
import styled from 'styled-components'
import jsonData from '../../../json/data.json'
import { ContainerImg, ContainerImgMobile, CsInput } from './style'

const Home = () => {
  const { account, chainId } = useActiveWeb3React()
  const { isMobile } = useMatchBreakpoints()
  const { theme } = useTheme()
  const [searchId, setSearchId] = useState('')
  const dataHome = GetDataHome()
  const { isLoading, dataById, metaData } = dataHome

  const renderAddress = (address: string) => {
    if (address?.length > 0) {
      return `${address.substring(0, 4)}...${address.substring(address.length - 4)}`
    }
    return address
  }

  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false)
  const [isTooltipDisplayedForId, setIsTooltipDisplayedForId] = useState(false)

  const handleCoppy = (address: string) => {
    if (navigator.clipboard && navigator.permissions) {
      navigator.clipboard.writeText(address).then(() => displayTooltipCode())
    } else if (document.queryCommandSupported('copy')) {
      const ele = document.createElement('textarea')
      ele.value = address
      document.body.appendChild(ele)
      ele.select()
      document.execCommand('copy')
      document.body.removeChild(ele)
      displayTooltipCode()
    }
  }

  const handleCoppyId = (idNumber: string) => {
    if (navigator.clipboard && navigator.permissions) {
      navigator.clipboard.writeText(idNumber).then(() => displayTooltipCodeId())
    } else if (document.queryCommandSupported('copy')) {
      const ele = document.createElement('textarea')
      ele.value = idNumber
      document.body.appendChild(ele)
      ele.select()
      document.execCommand('copy')
      document.body.removeChild(ele)
      displayTooltipCodeId()
    }
  }

  function displayTooltipCodeId() {
    setIsTooltipDisplayedForId(true)
    setTimeout(() => {
      setIsTooltipDisplayedForId(false)
    }, 3000)
  }

  function displayTooltipCode() {
    setIsTooltipDisplayed(true)
    setTimeout(() => {
      setIsTooltipDisplayed(false)
    }, 3000)
  }

  const handleChangeSearchQuery = async (value: string) => {
    if (value !== '') {
        setSearchId(value)
    } else {
        setSearchId("")
    }
  }

  const options = useMemo(() => {
    if(searchId) {
      const data = [...jsonData]
      return data?.filter((record) => record?.ID?.toString() === searchId?.toString() || record?.serialNumber?.toString() === searchId?.toString() )?.map((item) => {
        return { value: item?.serialNumber }
      })
    }
    return []
    
  }, [searchId])

  const useDebounce = (value: string | number | null, delay = 300) => {
    const [debouncedValue, setDebouncedValue] = useState(value);
  
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);
  
    return debouncedValue;
  };

  const debouncedQuery = useDebounce(searchId, 500);
  UseHome(account, chainId, debouncedQuery?.toString())


  return (
    <PageFullWidth>
      <Header pageTitle="ĐỒNG VÀNG CÔNG NGHỆ HANAGOLD" />
      <Container width="100%" mt={['-4rem', , '-10rem']}>
        <Flex
          width="100%"
          flexDirection={['column-reverse', , 'row']}
          padding="1rem"
          borderRadius="12px"
          background={theme.colors.primaryDark}
          justifyContent="space-between"
          style={{ gap: isMobile ? '2rem' : '0rem' }}
        >
          <ContainerImg
            width={['100%', , '48%']}
            style={{ gap: '1rem' }}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <img
              src={
                metaData?.image ??
                'https://bafkreiftix7faj2gqz3ld5j6l6meh7ooeq3s6dmhtnexhpf6qpgs3vs6c4.ipfs.nftstorage.link/'
              }
              alt="logo"
              style={{ width: isMobile ? '100%' : '500px', height: 'auto' }}
            />
          </ContainerImg>
          <Flex width={['100%', , '48%']} flexDirection="column" style={{ gap: '2rem' }}>
            <Flex width="100%">
              <Text fontSize={['22px', , '38px']} bold color="white">
                Giấy chứng nhận NFT đồng vàng công nghệ HanaGold
              </Text>
            </Flex>
            <Flex width="100%">
              <ButtonMenu activeIndex={0} fullWidth>
                <ButtonMenuItem>DETAILS</ButtonMenuItem>
                <ButtonMenuItem padding="10px">TRANSFER</ButtonMenuItem>
                <ButtonMenuItem padding="10px">BURN</ButtonMenuItem>
              </ButtonMenu>
            </Flex>
            <Flex width="100%">
              <AutoComplete
                style={{ width: '100%' }}
                options={options}
                filterOption={(inputValue, option) =>
                  option?.value?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
                onSelect={(text) => handleChangeSearchQuery(text)}
                onSearch={(text) => handleChangeSearchQuery(text)}
                onChange={(text) => handleChangeSearchQuery(text)}
                value={searchId}	
            >
                <CsInput placeholder="Nhập Serial Number" prefix={<SearchIcon />} allowClear />
            </AutoComplete>
            </Flex>
            <Flex
              width="100%"
              border="1px solid rgba(255, 255, 255, 0.20)"
              borderRadius="12px"
              padding="1rem"
              flexDirection="column"
              style={{ gap: '1.5rem' }}
            >
              <ContainerImgMobile width="100%" height="auto">
                <img
                  src={
                    metaData?.image ??
                    'https://bafkreiftix7faj2gqz3ld5j6l6meh7ooeq3s6dmhtnexhpf6qpgs3vs6c4.ipfs.nftstorage.link/'
                  }
                  alt="logo"
                  style={{ width: isMobile ? '100%' : '500px', height: 'auto' }}
                />
              </ContainerImgMobile>
              <Flex width="100%" justifyContent="space-between" alignItems="center">
                <Text color="subPrice" fontSize={['16px', , '18px']}>
                  Token ID
                </Text>
                {metaData?.ID && searchId !== '' && (
                  <Flex
                    width="auto"
                    padding="8px 12px"
                    // border="1px solid white"
                    style={{ gap: '12px' }}
                    alignItems="center"
                    borderRadius="12px"
                    position="relative"
                  >
                    <Text bold>{metaData?.ID}</Text>
                    <CopyIcon onClick={() => handleCoppyId(metaData?.ID)} style={{ cursor: 'pointer' }} />
                    <Tooltip isTooltipDisplayed={isTooltipDisplayedForId}>Copied</Tooltip>
                  </Flex>
                )}
              </Flex>
              <Flex width="100%" justifyContent="space-between" alignItems="center">
                <Text color="subPrice" fontSize={['16px', , '18px']}>
                  Owner
                </Text>
                {dataById?.ownerOf && searchId !== '' && (
                  <Flex
                    width="auto"
                    padding="8px 12px"
                    // border="1px solid white"
                    style={{ gap: '12px' }}
                    alignItems="center"
                    borderRadius="12px"
                    position="relative"
                  >
                    <Text bold>{renderAddress(dataById?.ownerOf)}</Text>
                    <CopyIcon onClick={() => handleCoppy(dataById?.ownerOf)} style={{ cursor: 'pointer' }} />
                    <Tooltip isTooltipDisplayed={isTooltipDisplayed}>Copied</Tooltip>
                  </Flex>
                )}
              </Flex>
              <Flex width="100%" justifyContent="space-between" alignItems="center">
                <Text color="subPrice" fontSize={['16px', , '18px']}>
                  Token Standard
                </Text>
                {metaData !== null && searchId !== '' && (
                  <Text fontSize={['16px', , '18px']} bold>
                    ERC721
                  </Text>
                )}
              </Flex>
              <Flex width="100%" justifyContent="space-between" alignItems="center">
                <Text color="subPrice" fontSize={['16px', , '18px']}>
                  NFC ID
                </Text>
                {metaData?.nfcId && searchId !== '' && (
                  <Text fontSize={['16px', , '18px']} bold>
                    {metaData?.nfcId}
                  </Text>
                )}
              </Flex>
              <Flex width="100%" justifyContent="space-between" alignItems="flex-start" style={{ gap: '8px' }}>
                <Text color="subPrice" fontSize={['16px', , '18px']}>
                  Serial Number
                </Text>
                {metaData?.serialNumber && searchId !== '' && (
                  <Text
                    fontSize={['16px', , '18px']}
                    bold
                    maxWidth="60%"
                    style={{ wordBreak: 'break-word' }}
                    textAlign="right"
                  >
                    {metaData?.serialNumber}
                  </Text>
                )}
              </Flex>
              <Flex width="100%" justifyContent="space-between" alignItems="flex-start">
                <Text color="subPrice" fontSize={['16px', , '18px']}>
                  Số lô
                </Text>
                {metaData?.lotNumber && searchId !== '' && (
                  <Text
                    maxWidth="60%"
                    style={{ wordBreak: 'break-word' }}
                    bold
                    textAlign="right"
                    fontSize={['16px', , '18px']}
                  >
                    {metaData?.lotNumber}
                  </Text>
                )}
              </Flex>
              <Flex width="100%" justifyContent="space-between" alignItems="center">
                <Text color="subPrice" fontSize={['16px', , '18px']}>
                  Ngày sản xuất
                </Text>
                {metaData?.issueDate && searchId !== '' && (
                  <Text fontSize={['16px', , '18px']} bold>
                    {metaData?.issueDate}
                  </Text>
                )}
              </Flex>
              <Flex width="100%" justifyContent="space-between" alignItems="center">
                <Text color="subPrice" fontSize={['16px', , '18px']}>
                  Status
                </Text>
                {metaData !== null && searchId !== '' && (
                  <Text color="#6AC005" fontSize={['16px', , '18px']} bold>
                    Active
                  </Text>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </PageFullWidth>
  )
}

export default Home

const Tooltip = styled.div<{ isTooltipDisplayed: boolean }>`
  display: ${({ isTooltipDisplayed }) => (isTooltipDisplayed ? 'inline-block' : 'none')};
  position: absolute;
  padding: 8px;
  top: -35px;
  right: -15px;
  text-align: center;
  background-color: #fff;
  color: #000;
  border-radius: 16px;
  width: 100px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
`
